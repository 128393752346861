import React from "react"
import NoheaderFooter from "../../../../../components/Noheaderfooter"
import SEO from "../../../../../components/seo"
import FaqsOpenApiMobile from "../../../../../components/body/pages/mobile/ng/legal/faq/faqOpenApi"

const FAQSOpenApiPage = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/en-ng/legal/faq/"}
      title="Frequently Asked Questions Open Api | Kuda | The Money App for Africans"
      description="Get a free debit card, no fees."
    />
    <FaqsOpenApiMobile />
  </NoheaderFooter>
)

export default FAQSOpenApiPage
